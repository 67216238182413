import { LoadableAfterPaint } from '@confluence/loadable';

export const LiveDocsIconTile = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-LiveDocsIconTile" */
				'../src/icons/LiveDocsIconTile'
			)
		).LiveDocsIconTile,
});
