import { useCallback, useState, useContext } from 'react';
import { useQuery } from 'react-apollo';
import type { ApolloError } from 'apollo-client';
import OriginTracer from '@atlassiansox/origin-tracing';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { RoutesContext } from '@confluence/route-manager';
import {
	SPACE_OVERVIEW,
	VIEW_PAGE,
	EDIT_PAGE_V2,
	VIEW_BLOG,
	WHITEBOARD_BOARD,
	EDIT_BLOG_V2,
} from '@confluence/named-routes';

import { useCopyToClipboard } from './useCopyToClipboard';
import type {
	CopyLinkQuery as CopyLinkQueryType,
	CopyLinkQueryVariables,
} from './__types__/CopyLinkQuery';
import { CopyLinkQuery } from './CopyLinkQuery.graphql';
import { ACTION_SUBJECT_ID } from './CopyLinkButton';
import type { CopyLinkProps } from './CopyLinkButton';

export type SupportedContentTypes = 'page' | 'blogpost' | 'overview' | 'whiteboard';

type UseCopyContentLinkReturn = {
	queryError: ApolloError | undefined;
	unsupportedLinkError: Error | undefined;
	makeContentLink: () => string | undefined;
	copyContentLink: () => Promise<void>;
};

export const useCopyContentLink = ({
	pageMode,
	contentType,
	contentId,
	spaceKey,
	source,
}: Omit<
	CopyLinkProps,
	'shouldResetOnHover | buttonAppearance | tooltipPosition'
>): UseCopyContentLinkReturn => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [unsupportedLinkError, setUnsupportedLinkError] = useState<Error | undefined>();
	const { toUrl } = useContext(RoutesContext);
	const { copyToClipboard } = useCopyToClipboard();

	const {
		data,
		loading,
		error: queryError,
	} = useQuery<CopyLinkQueryType, CopyLinkQueryVariables>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		CopyLinkQuery,
		{
			variables: { contentId },
		},
	);

	const makeContentLink = useCallback(() => {
		const origin = new OriginTracer({ product: 'confluence' });

		let copyLink;
		// Try to get the page link from queried data, shortened (for view mode) if possible.
		if (data) {
			const links = data?.singleContent?.links;
			if (pageMode === 'edit') {
				copyLink = links?.editui;
			} else {
				// pageMode === "view" OR no page mode specified, eg for whiteboards
				copyLink = links?.tinyui || links?.webui;
			}
			if (copyLink && links?.base) {
				const copyLinkUrl = `${links.base}${copyLink}`;
				return origin.addToUrl(copyLinkUrl);
			}
		}

		// Fallback behavior: Use content type + page mode to construct URL.
		let routeName, additionalParams;
		if (pageMode === 'edit') {
			routeName = contentType === 'blogpost' ? EDIT_BLOG_V2.name : EDIT_PAGE_V2.name;
			additionalParams = {
				contentType:
					// EDIT_BLOG_V2 and EDIT_PAGE_V2 routes expect particular contentType format during route construction
					contentType === 'blogpost' ? 'blog' : 'pages',
			};
		} else {
			switch (contentType) {
				case 'overview':
					routeName = SPACE_OVERVIEW.name;
					break;
				case 'blogpost':
					routeName = VIEW_BLOG.name;
					break;
				case 'page':
					routeName = VIEW_PAGE.name;
					break;
				case 'whiteboard':
					routeName = WHITEBOARD_BOARD.name;
					break;
				// omit databases and other unsupported content types
				default:
					const error = new Error(`Received an unexpected content type: '${contentType}'`);
					createAnalyticsEvent({
						type: 'sendOperationalEvent',
						data: {
							action: 'thrown',
							actionSubject: 'error',
							actionSubjectId: ACTION_SUBJECT_ID,
							source,
							attributes: {
								error: error?.message,
								contentType,
								pageMode,
							},
						},
					}).fire();
					setUnsupportedLinkError(error);
			}
		}

		if (routeName) {
			createAnalyticsEvent({
				type: 'sendOperationalEvent',
				data: {
					action: 'generated',
					actionSubject: 'fallbackLink',
					actionSubjectId: ACTION_SUBJECT_ID,
					source,
					attributes: {
						queryError: queryError?.message,
						isDataLoading: loading,
						route: routeName,
					},
				},
			}).fire();

			// Omit contentSlug, because user perception is that link sharing
			// is more secure without the content title in the URL.
			const copyLinkUrl = `${window.location.origin}${toUrl(routeName, {
				contentId,
				spaceKey,
				...additionalParams,
			})}`;

			return origin.addToUrl(copyLinkUrl);
		}
		return undefined;
	}, [
		toUrl,
		contentId,
		contentType,
		spaceKey,
		pageMode,
		source,
		data,
		loading,
		queryError,
		createAnalyticsEvent,
	]);

	const copyContentLink = useCallback(() => {
		return copyToClipboard(makeContentLink());
	}, [makeContentLink, copyToClipboard]);

	return {
		queryError,
		unsupportedLinkError,
		makeContentLink,
		copyContentLink,
	};
};
