import React, { useRef, useState, useEffect, useLayoutEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
import type { MessageDescriptor } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';
import LinkIcon from '@atlaskit/icon/core/link';
import Button, {
	IconButton,
	type IconButtonAppearance,
	type Appearance,
} from '@atlaskit/button/new';
import type { PositionType } from '@atlaskit/tooltip';
import type { NewCoreIconProps } from '@atlaskit/icon/base-new';
import Tooltip from '@atlaskit/tooltip';

import { END } from '@confluence/navdex';

import { useCopyContentLink } from './useCopyContentLink';

const i18n = defineMessages({
	copyLinkLabel: {
		id: 'copy-content-link.icon.label',
		defaultMessage: 'Copy link',
		description: 'Label and tooltip for the button to copy content URL',
	},
	copiedTextLabel: {
		id: 'copy-content-link.copied.tooltip',
		defaultMessage: 'Copied!',
		description:
			'Text for the copy link button tooltip when content URL has been successfully copied',
	},
	errorCopyingTextLabel: {
		id: 'copy-content-link.error.tooltip',
		defaultMessage: 'Something went wrong.',
		description:
			'Text for the copy link button tooltip when an error occurred while trying to copy link',
	},
});

const DEFAULT_TOOLTIP_DELAY = 100;
const RESET_COPYTEXT_TIMEOUT = 2500;

const iconButtonAppearances = new Set<IconButtonAppearance>([
	'default',
	'primary',
	'discovery',
	'subtle',
]);
const formatIconButtonAppearance = (appearance: Appearance): IconButtonAppearance =>
	iconButtonAppearances.has(appearance as IconButtonAppearance)
		? (appearance as IconButtonAppearance)
		: 'default';

export const ACTION_SUBJECT_ID = 'copyShareLink';

export type PageMode = 'view' | 'edit';

export type CopyLinkProps = {
	pageMode?: PageMode;
	contentType: string;
	contentId: string;
	spaceKey: string;
	source: string;
	isDraft?: boolean;
	isLivePage?: boolean;
	isDisabled?: boolean;
	shouldResetOnHover?: boolean;
	buttonAppearance?: Appearance;
	tooltipPosition?: PositionType;
	shouldFitContainer?: boolean;
	buttonText?: string | React.ReactElement;
	copiedTextTooltipLabel?: MessageDescriptor;
	onClickAttributes?: { [key: string]: string };
	iconColor?: NewCoreIconProps['color'];
};

export const CopyLinkButton: React.FC<CopyLinkProps> = ({
	pageMode,
	contentType,
	contentId,
	spaceKey,
	source,
	isDraft = false,
	isLivePage = false,
	isDisabled = false,
	shouldResetOnHover = false,
	buttonAppearance = 'subtle',
	tooltipPosition = 'bottom',
	shouldFitContainer = true,
	buttonText = '',
	copiedTextTooltipLabel = i18n.copiedTextLabel,
	onClickAttributes,
	iconColor,
}) => {
	const intl = useIntl();
	const cleanupCopiedTimer = useRef<NodeJS.Timeout>();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [isCopied, setIsCopied] = useState<boolean>(false);
	const [errorDuringCopy, setErrorDuringCopy] = useState<Error | undefined>();
	const updateTooltip = React.useRef<() => void>();

	useEffect(() => {
		if (isCopied) {
			cleanupCopiedTimer.current = setTimeout(() => setIsCopied(false), RESET_COPYTEXT_TIMEOUT);
		}
		return () => {
			clearTimeout(cleanupCopiedTimer.current);
		};
	}, [isCopied]);

	const { unsupportedLinkError, copyContentLink } = useCopyContentLink({
		pageMode,
		contentType,
		contentId,
		spaceKey,
		source,
	});

	const handleCopyLink = async () => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'button',
				actionSubjectId: ACTION_SUBJECT_ID,
				source,
				attributes: {
					pageMode,
					contentType,
					navdexPointType: END,
					isDraft,
					isLivePage,
					...(onClickAttributes ?? {}),
				},
			},
		}).fire();

		try {
			await copyContentLink();
		} catch (e) {
			setErrorDuringCopy(e);
		}
		setIsCopied(true);
	};

	// report errors from the hook's query silently since we have fallback link retrieval
	// methods, but show user error tooltip text if we actually
	// couldn't get any link to copy or the copy itself failed
	const copyError = unsupportedLinkError || errorDuringCopy;
	const copyLinkText = intl.formatMessage(i18n.copyLinkLabel);
	const confirmationText = intl.formatMessage(
		copyError ? i18n.errorCopyingTextLabel : copiedTextTooltipLabel,
	);

	const onMouseLeave = () => {
		if (shouldResetOnHover) {
			setIsCopied(false);
		}
	};

	useLayoutEffect(() => {
		updateTooltip.current?.();
	}, [isCopied]);

	const iconButtonAppearance = formatIconButtonAppearance(buttonAppearance);

	return (
		<>
			<Tooltip
				content={({ update }) => {
					updateTooltip.current = update;
					return isCopied ? confirmationText : copyLinkText;
				}}
				position={tooltipPosition}
				ignoreTooltipPointerEvents
				delay={DEFAULT_TOOLTIP_DELAY}
			>
				{buttonText ? (
					<Button
						testId="copy-link-button"
						aria-label={copyLinkText}
						appearance={buttonAppearance}
						iconBefore={LinkIcon}
						onClick={handleCopyLink}
						onMouseLeave={onMouseLeave}
						isDisabled={isDisabled}
						shouldFitContainer={shouldFitContainer}
					>
						{buttonText}
					</Button>
				) : (
					<IconButton
						testId="copy-link-button"
						label={copyLinkText}
						icon={(iconProps) => <LinkIcon {...iconProps} color={iconColor} />}
						onClick={handleCopyLink}
						onMouseLeave={onMouseLeave}
						isDisabled={isDisabled}
						appearance={iconButtonAppearance}
					/>
				)}
			</Tooltip>
		</>
	);
};
